.image-gallery {
  width: 100%;
  height: 300px;
}

.image-gallery-slide img {
  width: 100%;
  height: 300px;
  max-height: 80vh;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
}

.fullscreen .image-gallery-slide img {
  max-height: 100vh;
}

.image-gallery {
  background-color: black;
  overflow: hidden;
}

.image-gallery-bullets .image-gallery-bullet {
  background: #edeff6;
  border-color: #edeff6;

  &.active {
    background: #2255ff;
    border-color: #2255ff;
  }
}

.image-gallery-slide {
  height: 300px;
}

.image-gallery-slide .image-gallery-image {
  height: 100%;
  object-fit: cover;
}

.image-gallery-slide .image-gallery-description {
  z-index: 10;
  font-size: 22px;
  font-weight: bold;
  text-align: right;
  text-shadow: 1px 1px 1px black;
  bottom: 25%;
  height: 20%;
  width: 320px;
  opacity: 1;
  word-wrap: break-word;
  background: none;
}
